<template>
  <div>
    <b-row>
      <b-col cols="6">
        <b-overlay
            :show="loadRequest"
            rounded="sm"
          >
          <b-card title="ШИНЭ АЖИЛ(ДУУДЛАГА ХИЙХ)">
            <new-inbound-todo :task="task" :clear-task-data="clearTaskData" @add-task="addTask"
              @update-task="updateTask"  @change-customer-phone="changeCustomerPhone"></new-inbound-todo>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col lg="6" cols="12" v-if="appUsername">
        <b-card :title=" '(' + appUsername + ') ХЭРЭГЛЭГЧИЙН БҮРТГҮҮЛСЭН АЖИЛБАРУУД'">
          <b-overlay
            :show="loadRequest"
            rounded="sm"
          >
            <ticket-list :appUsername="appUsername"></ticket-list>
          </b-overlay>
        </b-card>
        <b-card :title=" '(' + appUsername + ') AДМИН ТЭМДЭГЛЭЛҮҮД'">
          <b-overlay
            :show="loadRequest"
            rounded="sm"
          >
            <note-list :appUsername="appUsername"></note-list>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import store from '@/store'
import { ref, computed, onUnmounted } from '@vue/composition-api'
import { BRow, BCol, BCard, BCardBody, BCardTitle, BCardSubTitle, BTable, BOverlay} from 'bootstrap-vue'
import UserViewUserInfoCard from './components/UserViewUserInfoCard.vue'
import CardStatisticsGroup from './components/CardStatisticsGroup.vue'
import NewInboundTodo from './components/NewInboundTodo.vue'
import TicketList from './components/TicketList.vue'
import NoteList from './components/NoteList.vue'
import todoStoreModule from '@/views/todo/todoStoreModule'
import Ripple from 'vue-ripple-directive'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useRouter } from '@core/utils/utils'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
let id = 1000;
export default {
  components: {
    BCard,
    BCardBody, 
    BCardTitle, 
    BCardSubTitle,
    BRow,
    BCol,
    BTable,
    UserViewUserInfoCard,
    CardStatisticsGroup,
    NewInboundTodo,
    TicketList,
    NoteList,
    BOverlay
  },
  directives: {
    Ripple,
  },
  setup () {
    const loadRequest = ref(false)
    const toast = useToast()
    
    const { route, router } = useRouter()

    const TODO_APP_STORE_MODULE_NAME = 'app-todo'

    // Register module
    if (!store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.registerModule(TODO_APP_STORE_MODULE_NAME, todoStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.unregisterModule(TODO_APP_STORE_MODULE_NAME)
    })

    const routeParam = computed(() => route.value.params.phone)
    const appUsername = ref(route.value.params.phone)

    const blankTask = {
      id: null,
      title: '',
      // uurt ni bsan
      dueDate: new Date(),
      description: '',
      assignee: null,
      isCompleted: false,
      isDeleted: false,
      isImportant: false,
      // nemsen
      code: '',
      phone: appUsername.value,
      status: 'Шинэ',
      categoryIds: [],
      productIds: [],
      tagIds: [],
      attachmentNames: [],
      priority: '',
      workspace: 'Нөмөр Кредит ББСБ',
      space: 'Нөмөр асуудал',
      folder: 'Нээлттэй хавтас',
      board: 'Нээлттэй самбар',
      list: 'Шинэ',
      due_date: new Date(),
      is_complete: false,
      is_draft: false,
      is_sent: false,
      created_at: new Date(),
      created_by: '',
      customer_id: '',
      merchant_id: '',
      firstname: '',
      lastname: '',
      organization: 'Нөмөр Кредит ББСБ',
      team: '',
      is_deleted: false,
      // deleted_at: null,
      // deleted_by: '',
      call_type: 'outbound',
      pay_score: 0,
      promised_at: null,
      started_at: null,
      finished_at: null,
      returned_at: null,
      completed_at: null,
      comments: [
      ],
      comment: '',
      customer: {
        customer_id: 0,
        customer_per_id: 0,
        firstname: '',
        lastname: '',
        register_number: '',
        ticket_id: 0,
        phone: appUsername.value,
      }
    }
    const task = ref(JSON.parse(JSON.stringify(blankTask)))
    const clearTaskData = () => {
      task.value = JSON.parse(JSON.stringify(blankTask))
    }

    const addTask = val => {
      loadRequest.value = true
      console.log('val', val, loadRequest)
      store.dispatch('app-todo/addTask', val)
        .then(response => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'BellIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right'
          })
          clearTaskData()
          loadRequest.value = false
        }).catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Алдаа гарлаа',
              icon: 'BellIcon',
              variant: 'danger',
            },
          },
          {
            position: 'bottom-right'
          })
          loadRequest.value = false
        })
    }
    const removeTask = () => {
      store.dispatch('app-todo/removeTask', { id: task.value.id })
        .then(() => {
          console.log('1')
        })
    }
    const updateTask = taskData => {
      store.dispatch('app-todo/updateTask', { task: taskData })
        .then(() => {
          clearTaskData()
        })
    }
    const changeCustomerPhone = param => {
      console.log(param)
      appUsername.value = param
    }

    return {
      changeCustomerPhone,
      task,
      removeTask,
      addTask,
      updateTask,
      clearTaskData,
      loadRequest,
      routeParam,
      appUsername
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

#quil-content {
  .ql-container {
    border-bottom: none;
    min-height: 100px;
  }

  +#quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.el-button--text {
  color: #13795b;
}

.el-button--text::-ms-expand {
  color: #13795b;
}


.el-button--text:hover {
  color: #13795b;
}

.el-checkbox__inner:hover {
  border-color: #13795b;
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #13795b;
  border-color: #13795b;
}

iframe {
  border: none;
}
</style>
